import {
  Conflux,
  format,
  Drip,
} from "js-conflux-sdk/dist/js-conflux-sdk.umd.min.js";

import { abi as posPoolAbi } from "./../assets/contract/ABI/IPoSPool.json";
//import { abi as posManagerAbi } from "./../assets/contract/ABI/PoolManager.json";
//import { isTestNetEnv } from "./index";
//import poolconfig from './pool.config';

const networkId = 1029;
const cfxUrl = 'http://111.198.75.72'; //poolConfig.mainnet.RPC;  http://111.198.75.72  https://main.confluxrpc.com

let conflux = new Conflux({
  url: cfxUrl,
  networkId,
});
conflux.provider = window.conflux;

const getContract = (abi, address) =>
  conflux.Contract({
    abi: abi,
    address: address,
  });

const getPosPoolContract = (address) =>
  conflux.Contract({
    abi: posPoolAbi,
    address: address,
  });

const getAddress = async () => {
  let accounts = null;
  let _address = "";
  try {
    accounts = await conflux.request({ method: "cfx_requestAccounts" });
    _address = accounts + "";
    window._address = _address;
  } catch (error) {
    //console.log(error);
  }
  if (_address === "") {
    try {
      accounts = await conflux.enable();
    } catch (error) { }
    // 新版本手机
    if (window.isArrayFn(accounts)) {
      _address = accounts[0];
    } else {
      const accounts2 = await conflux.send({ method: "cfx_accounts" });
      _address = accounts2.result[0];
    }
    window._address = _address;
  }
  localStorage.setItem("_address", _address);

  try {
    document.getElementById("accname").innerHTML =
      '<div class="qb_btn">' +
      _address.substring(0, 6) +
      "..." +
      _address.substring(45, _address.lenght) +
      "</div>";
  } catch (error) {

  }


  return _address;
}

export const getPosAccountByPowAddress = async (address) => {
  const posPoolContract = await getPosPoolContract(address);
  const posAddress = format.hex(await posPoolContract.posAddress());
  const posAccout = await conflux.provider.call("pos_getAccount", posAddress);
  return posAccout;
};
export {
  conflux,
  format,
  Drip,
  getPosPoolContract,
  getContract,
  getAddress
};
